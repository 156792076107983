import { graphql } from "gatsby"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { Autoplay } from "swiper"
import "swiper/css"
import { Swiper, SwiperSlide } from "swiper/react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import hakkimizda1 from "../images/hakkimizda-1.png"
import hakkimizda2 from "../images/hakkimizda-2.png"
import hakkimizda3 from "../images/hakkimizda-3.png"
import hakkimizdaVideo from "../images/hakkimizda-video.png"
import "../styles/hakkimizda.css"

const About = props => {
  const { t } = useTranslation()
  const { language } = useI18next()

  const aboutContent = props.data?.about?.nodes?.[0]?.frontmatter

  return (
    <Layout>
      <Seo title={t("Hakkimizda")} />
      <div className="w-9/12 mx-auto mt-48 flex items-center justify-between md:flex-col md:w-10/12 md:mt-24">
        <div className="w-1/5 flex flex-col md:hidden">
          <img src={hakkimizda1} alt="hakkimizda 1" className="w-full mb-6" />
          <img src={hakkimizda2} alt="hakkimizda 2" className="w-full mb-6" />
          <img src={hakkimizda3} alt="hakkimizda 3" className="w-full" />
        </div>
        <div className="w-4/6 flex flex-col md:w-full">
          <h2 className="font-second text-default-blue text-7xl mb-12 text-left md:text-center md:text-4xl leading-none font-normal md:mb-3">
            {t("biz")} <br className="md:hidden" />
            {t("kimiz")}
          </h2>
          <p className="font-first text-black text-xl font-light w-full mb-12 md:text-sm md:mb-8 whitespace-pre-line">
            {language === "en"
              ? aboutContent?.content_en
              : aboutContent?.content}
          </p>
          <a
            href="https://youtu.be/BPFABUSkFXU"
            target={"_blank"}
            rel="noreferrer"
            className="w-3/5 mx-auto md:w-4/5"
          >
            <img
              src={hakkimizdaVideo}
              alt="hakkimizda video"
              className="w-full"
            />
          </a>
          <p className="w-2/3 text-center font-first text-xl text-black font-light mx-auto mt-10 md:text-sm md:w-full md:mt-8">
            {language === "en"
              ? aboutContent?.content_footer_en
              : aboutContent?.content_footer}
          </p>
        </div>
        <Swiper
          loop="true"
          modules={[Autoplay]}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          className="hidden md:block md:mt-2 w-full mb-8"
          spaceBetween={0}
          slidesPerView={1}
          pagination={{ clickable: true }}
        >
          <SwiperSlide
            style={{
              background: `url(${hakkimizda1})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></SwiperSlide>
          <SwiperSlide
            style={{
              background: `url(${hakkimizda2})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></SwiperSlide>
          <SwiperSlide
            style={{
              background: `url(${hakkimizda3})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></SwiperSlide>
        </Swiper>
      </div>
    </Layout>
  )
}

export default About

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    about: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "about" } } }
    ) {
      nodes {
        frontmatter {
          content
          content_en
          content_footer
          content_footer_en
        }
      }
    }
  }
`
